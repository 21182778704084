<template>
  <div class="columns p__top--5 doc-attr" v-if="loaded">
    <!--    input text-->
    <div class="column is-12 p--10 p__top--5" v-if="inputType === 'text'">
      <input :type="inputType"
             v-model.trim="inputText"
             class='input fs-12 color__blue_button'
             :ref="'inputText' + index"
             @input="emitter('inputText')">
    </div>

    <!--    input select options-->
    <div class="column is-12 p--10 p__top--5" v-else-if="inputType === 'select' && inputOptions.length">
      <div class="dropdown w--100"
           :id="'dropdown-custom-input-' + index"
           v-click-out="()=>closeDropdown('dropdown-custom-input-' + index)">
        <div class="dropdown-trigger w--100">
          <button class="button w--100 is-justify-content-flex-start border-grey color__grey"
                  aria-haspopup="true"
                  @click="toggleDropdown('dropdown-custom-input-' + index)">
            <span class="fs-12 color__blue_button fw__bold">{{ inputSelect.name || '' }}</span>
            <span class="icon is-small">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
          </button>
        </div>
        <div class="dropdown-menu w--100" role="menu">
          <div class="dropdown-content">
            <a @click="getSelectData(e)"
               v-for="(e, i) in inputOptions"
               :key="'doc-type-' + i"
               :class="{'is-active': e.id === inputSelect}"
               class="dropdown-item fs-12">
              {{ e.name }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--    input multiselect-->
    <div class="column is-12 p__left--0 p__top--5 p__bottom--10"
         v-else-if="inputType === 'multiselect' && inputOptions.length">
      <multiselect :list-data="inputOptions"
                   placeholder="組織"
                   :selected="inputMultiSelect"
                   @change="getMultiSelectData($event)">
      </multiselect>
    </div>

    <!--    input checkbox-->
    <div class="column is-12 p--10 p__top--5" v-else-if="inputType === 'checkbox' && inputOptions.length">
      <div class="checkbox-group d-flex">
        <label class="checkbox m__right--20 fs-12" v-for="(e, i) in inputCheckbox" :key="'checkbox-input-custom-' + i">
          <input type="checkbox"
                 :value="e.id"
                 :id="'checkbox-custom-' + index + '-' + i"
                 @change="emitter('inputCheckbox')"
                 v-model="e.checked">
          {{ e.name }}
        </label>
      </div>
    </div>

    <!--    input radio-->
    <div class="column is-12 p--10 p__top--5 p__bottom--20 p__left--0" v-else-if="inputType === 'radio' && inputOptions.length">
      <div class="radio-group d-flex">
        <label class="radio d-flex align-items-center fs-12" v-for="(e, i) in inputOptions" :key="'radio-input-custom-' + i">
          <input type="radio"
                 v-model="inputRadio"
                 :value="e.id"
                 class="m__right--5 radio-input"
                 :id="'radio-custom-' + index + '-' + i"
                 :name="'radio-custom-' + index"
                 @change="emitter('inputRadio')">
          {{ e.name }}
        </label>
      </div>
    </div>

    <!--    input date-->
    <div v-else-if="inputType === 'date'" class="column is-12">
      <div class="columns">
        <div class="column is-12 p--5 p__left--5 p__top--5">
          <date-picker v-model="inputDate"
                       format="YYYY-MM-DD"
                       type="date"
                       lang="ja"
                       :class="{'is-error': !dateMax(inputDate)}"
                       class="w--100">
            <template slot="icon-calendar">
              <img class="img-calendar" src="@/assets/svgs/ic_calendar.svg">
            </template>
          </date-picker>
        </div>
      </div>
      <div class="columns">
        <span class="color-error column is-full p--0 p__left--5" v-if="!dateMax(inputDate)">
          {{ $t('documents.messages.validation.date_max_invalid') }}
        </span>
      </div>
    </div>

    <!--        textbox-->
    <div class="column is-12 p--10 p__top--5" v-else-if="inputType === 'textbox'">
      <textarea v-model.trim="inputText"
                rows="5"
                @keyup.enter.stop
                class="w--100 p--8 no-outline br--5 color__blue_main"
                :ref="'inputText' + index"
                @input="emitter('inputText')">
      </textarea>
    </div>

    <!--    input date range-->
    <div class="column is-12" v-else-if="inputType === 'daterange'">
      <div class="columns">
        <div class="column p--5 p__top--5 is-5">
          <date-picker v-model="dateRange.start"
                       :disabled-date="dateRangeMax"
                       format="YYYY-MM-DD"
                       type="date"
                       lang="ja"
                       class="w--100">
            <template slot="icon-calendar">
              <img class="img-calendar" src="@/assets/svgs/ic_calendar.svg">
            </template>
          </date-picker>
        </div>
        <div class="column is-1 p--5 p__top--5 range-date m-auto has-text-centered">
          <span>～</span>
        </div>
        <div class="column p--5 p__top--5 is-5 p__right--10">
          <date-picker v-model="dateRange.end"
                       :disabled-date="dateRangeMin"
                       :class="{'is-error': dateRange.end && dateRange.start > dateRange.end}"
                       format="YYYY-MM-DD"
                       type="date"
                       lang="ja"
                       class="w--100">
            <template slot="icon-calendar">
              <img class="img-calendar" src="@/assets/svgs/ic_calendar.svg">
            </template>
          </date-picker>
          <span class="color-error column is-full p--0 p__left--5"
                v-if="dateRange.end && dateRange.start > dateRange.end">
          {{ $t('documents.messages.validation.date_start_smaller') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import {attributeInputTypes} from "@/helpers/constant"
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/locale/ja'
  import isEqual from 'lodash/isEqual'

  export default {
    name: "custom-input",
    components: {DatePicker},
    props: {
      type: {
        type: [Number, String],
        default: 'text'
      },
      options: {
        type: [Array, Object, String],
        default: () => []
      },
      value: {
        type: [Array, String, Object, Date, Number],
        default: null
      },
      index: {
        type: Number,
        default: null
      },
      attributeId: {
        type: [Number, String],
        default: null
      },
      attributeType: {
        type: [Number, String],
        default: null
      },
      attributesLength: {
        type: [Number, String],
        default: null
      },
      maxRange: {
        type: [String, Date],
        default: ''
      },
      maxDate: {
        type: [String, Date],
        default: ''
      }
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    data() {
      return {
        valueClone: {},
        loaded: true,
        inputType: '',
        inputOptions: [],
        inputText: this.valueClone && this.valueClone.value,
        inputSelect: this.valueClone && this.valueClone.value,
        inputMultiSelect: this.valueClone && this.valueClone.value,
        inputCheckbox: this.valueClone && this.valueClone.value,
        inputRadio: this.valueClone && this.valueClone.value,
        inputDate: this.valueClone && new Date(this.valueClone.value),
        dateRange: this.valueClone ? this.valueClone.value : {start: '', end: ''}
      }
    },
    computed: {
      niceDate() {
        return this.date && this.date.toLocaleDateString()
      }
    },
    watch: {
      value(newVal, oldVal) {
        if (newVal && !isEqual(newVal, oldVal)) {
          this.inputType = attributeInputTypes[this.type]
          this.valueClone = {...newVal}
          // console.log(this.inputType, newVal)
          // this.inputText = this.valueClone && this.valueClone.value
          switch (this.inputType) {
            case 'text':
            case 'textbox':
              this.inputText = this.valueClone && this.valueClone.value
              break;
            case 'select':
              this.inputSelect = this.valueClone && this.valueClone.value
              break;
            case 'multiselect':
              this.inputMultiSelect = this.valueClone && this.valueClone.value
              break;
            case 'checkbox':
              this.inputCheckbox = this.valueClone && this.valueClone.value
              break;
            case 'radio':
              this.inputRadio = this.valueClone && this.valueClone.value
              break;
            case 'daterange':
              this.dateRange = this.valueClone ? {
                start: this.valueClone.value.start ? new Date(this.valueClone.value.start) : '',
                end: this.valueClone.value.end ? new Date(this.valueClone.value.end) : ''
              } : {start: '', end: ''}
              break;
            case 'date':
              this.inputDate = this.valueClone.value ? new Date(this.valueClone.value) : ''
              break;
          }
        }
      },
      maxDate(val) {
        this.$emit('invalid', !this.dateMax(this.maxRange ? new Date(this.maxRange) : '', val))
      },
      maxRange(val) {
        this.$emit('invalid', !this.dateMax(val ? new Date(val) : '', this.maxDate))
      },
      type(val) {
        if (val) {
          this.inputType = attributeInputTypes[this.type]
        }
      },
      inputDate(val) {
        this.$emit('input', {
          id: this.attributeId,
          type: this.attributeType,
          value: val ? moment(val).format('YYYY-MM-DD') : ''
        })
        // this.$emit('invalid', !this.dateMax(new Date(val), this.maxDate))
      },
      'dateRange.start': function (val) {
        let data = {
          start: val ? moment(val).format('YYYY-MM-DD') : '',
          end: this.dateRange.end ? moment(this.dateRange.end).format('YYYY-MM-DD') : ''
        }
        this.$emit('input', {id: this.attributeId, type: this.attributeType, value: data})
        this.$emit('invalid', this.dateRange.end && val > this.dateRange.end)
      },
      'dateRange.end': function (val) {
        let data = {
          start: this.dateRange.start ? moment(this.dateRange.start).format('YYYY-MM-DD') : '',
          end: val ? moment(val).format('YYYY-MM-DD') : ''
        }
        this.$emit('input', {id: this.attributeId, type: this.attributeType, value: data})
        this.$emit('invalid', val && val < this.dateRange.start)
      },
      options(val) {
        this.inputOptions = val
        this.inputOptions.map((e) => {
          e.checked = false
          return e
        })
        this.inputOptions.map((e) => {
          e.checked = false
          return e
        })
      }
    },
    methods: {
      emitter(input) {
        switch (input) {
          case 'inputText': {
            this.$emit('input', {id: this.attributeId, type: this.attributeType, value: this[input]})
            break
          }
          case 'inputCheckbox': {
            let data = []
            this[input].map((e) => {
              if (e.checked) {
                data = [...data, ...[e.id]]
              }
            })
            this.$emit('input', {id: this.attributeId, type: this.attributeType, value: data})
            break
          }
          case 'inputRadio': {
            this.$emit('input', {id: this.attributeId, type: this.attributeType, value: this[input]})
            break
          }
          default: {
            this.$emit('input', {id: this.attributeId, type: this.attributeType, value: this[input]})
            break
          }
        }
      },
      dateMax(date, max = this.maxDate) {
        return (date && this.maxDate) ? date <= new Date(max) : true
      },
      dateRangeMin(date) {
        let start = new Date(this.dateRange.start)
        start.setDate(start.getDate() - 1)
        return date < start
      },
      dateRangeMax(date) {
        return date > new Date(this.dateRange.end)
      },
      getSelectData(e) {
        this.inputSelect = e
        this.$emit('input', {id: this.attributeId, type: this.attributeType, value: this.inputSelect.id})
        this.closeDropdown('dropdown-custom-input-' + this.index)
      },
      getMultiSelectData(val) {
        this.inputMultiSelect = val.map((e) => {
          return e.id
        })
        this.$emit('input', {id: this.attributeId, type: this.attributeType, value: this.inputMultiSelect})
      },
      closeDropdown(id) {
        this.$nextTick(() => {
          this.$el.querySelector(`#${id}`).classList.remove('is-active')
        })
      },
      toggleDropdown(id) {
        this.$nextTick(() => {
          this.$el.querySelector(`#${id}`).classList.toggle('is-active')
          this.$refs['dropdown-keyword'] && this.$refs['dropdown-keyword'].focus()
        })
      },
    },
    mounted() {
      this.inputType = attributeInputTypes[this.type]

      this.valueClone = {...this.value}
      switch (this.inputType) {
        case 'text':
        case 'textbox':
          this.inputText = this.valueClone && this.valueClone.value
          break;
        case 'select':
          this.inputSelect = this.valueClone && this.valueClone.value
          break;
        case 'multiselect':
          this.inputMultiSelect = this.valueClone && this.valueClone.value
          break;
        case 'checkbox':
          this.inputCheckbox = this.valueClone && this.valueClone.value
          break;
        case 'radio':
          this.inputRadio = this.valueClone && this.valueClone.value
          break;
        case 'daterange':
          this.dateRange = this.valueClone.value ? {
            start: this.valueClone.value.start ? new Date(this.valueClone.value.start) : '',
            end: this.valueClone.value.end ? new Date(this.valueClone.value.end) : ''
          } : {start: '', end: ''}
          break;
        case 'date':
          this.inputDate = this.valueClone.value ? new Date(this.valueClone.value) : null
          break;
      }
      this.$nextTick(() => {
        if (this.options && this.options.length) {
          this.inputOptions = this.options
          this.inputOptions.map((e) => {
            e.checked = false
            return e
          })
          this.inputOptions.map((e) => {
            e.checked = false
            return e
          })
        }
      })
    }
  }
</script>

<style lang="scss">
  .doc-attr{
    .column {
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-top: 0px !important;
      .columns {
        margin: 0px !important;
      }
    }
  }
  textarea {
    border: 1px solid #E4E4E4 !important;
  }
  .radio-input {
    &:hover {
      border-radius: 100% !important;
    }
  }
  .img-calendar {
    width: 20px;
    height: 20px;
  }
  .mx-datepicker {
    border-radius: 5px !important;
    &.is-error {
      border: 1px solid $danger !important;

      &:focus, &:hover {
        box-shadow: 0 0 0 0.125em rgba(255, 102, 115, 0.35) !important;
      }

      .mx-input-wrapper {
        .mx-input {
          &:focus, &:hover {
            box-shadow: 0 0 0 0.125em rgba(255, 102, 115, 0.35) !important;
          }
        }
      }
    }

    .mx-input-wrapper {
      .mx-input {
        border: 1px solid #E4E4E4;
        border-radius: 5px !important;
        padding-left: 20px !important;
        font-size: 12px;
        font-weight: bold;
        color: $blue_button;
      }
    }
  }
  @media screen and (max-width: 495px) {
    .range-date {
      display: none !important;
    }
    .page-main-content {
      .columns {
        .column {
          padding: 10px !important;

          &:first-child {
            padding-bottom: 5px !important;
          }

          &:nth-child(2) {
            padding-top: 5px !important;
          }

          &.width-checkbox {
            .is-flex {
              display: grid !important;

              &.mt-3 {
                margin-top: 0 !important;
              }
            }
          }
        }
      }
    }
  }
  @media screen and(max-width: 1366px) {
    .doc-attr{
      .column {
        padding-left: 0px !important;
        padding-right: 0px !important;
        .columns {
          margin: 0px !important;
        }
      }
    }
  }
</style>