import ApiService from '@/services/ApiService';

const FolderService = {
  list(document_id = null) {
    if (document_id) {
      return ApiService.get(`folders?document_id=${document_id}`)
    }
    return ApiService.get('folders')
  },

  tree() {
    return ApiService.get('tree-folder')
  },

  create(data) {
    return ApiService.post('folders', data)
  },

  update(id, data) {
    return ApiService.update(`folders/${id}`, data)
  },

  delete(id) {
    return ApiService.delete(`folders/${id}`)
  },

  detail(id) {
    return ApiService.get(`folders/${id}`)
  },
}

export default FolderService;