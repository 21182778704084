import BaseModel from './BaseModel';

export default class DocumentModel extends BaseModel {
  constructor(props) {
    super(props);
    this.id = null
    this.name = ''
    this.folder_id = null
    this.branch_id = null
    this.branch_name = ''
    this.office_id = null
    this.office_name = ''
    this.division_id = null
    this.division_name = ''
    this.document_object = null
    this.partner_name = ''
    this.document_type = ''
    this.document_type_name = ''
    this.attribute_values = []
    this.files_info = []
    this.tags = []
    this.addTimesColumn()

    this.syncProps(props)
  }
}
