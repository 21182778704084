import isArray from 'lodash/isArray'

const findDeep = (array, condition, key, deepBy, type) => {
    let result = {}

    function fd(array, condition, key, deepBy) {
        array.map((e) => {
            if (e[key] === condition && e.type === type) {
                result = e
            } else if (e[deepBy] && e[deepBy].length > 0) {
                fd(e[deepBy], condition, key, deepBy)
            }
        })
    }

    fd(array, condition, key, deepBy)

    return result
}

const setDeep = (array, condition, key, deepBy, type, value) => {
    function sd(array, condition, key, deepBy) {
        array.map((e) => {
            if (e[key] === condition && e.type === type) {
                e[deepBy] = [...value[deepBy]]
                return array
            } else if (e[deepBy] && e[deepBy].length > 0) {
                sd(e[deepBy], condition, key, deepBy)
            }
        })
    }

    sd(array, condition, key, deepBy)

    return array
}

const getDeep = (array, condition, key, deepBy, parent = 'parent_id') => {
    let result = {}
    let tree = []

    let flatten = flattenDeep(array, deepBy)

    function gd(array, condition, key, deepBy) {
        array.map((e) => {
            if ((e[key] === condition)) {
                result = e
            }
            if (e[deepBy] && e[deepBy].length > 0) {
                gd(e[deepBy], condition, key, deepBy)
            }
        })
    }

    gd(array, condition, key, deepBy)

    function ff(array, condition, key, parent) {
        let current = {}
        array.map((e) => {
            if ((e[key] === condition)) {
                current = e
                tree.push(e)
            }
        })
        if (current[parent]) {
            ff(array, current[parent], key, parent)
        }
    }

    ff(flatten, result[parent], key, parent)

    return tree
}

const checkDeep = (array, condition, key = [], flag, deepBy) => {
    function cd(array, condition, key, flag, deepBy) {
        array.map((e) => {
            e[flag] = key.includes(e[condition])
            if (e[deepBy] && e[deepBy].length > 0) {
                cd(e[deepBy], condition, key, flag, deepBy)
            }
        })
    }

    cd(array, condition, key, flag, deepBy)

    return array
}

const groupDeep = (array, from = 'documents', target = 'children', type = 'folders') => {
    function gr(array, from, target, type) {
        array.map((e) => {
            if (!e.parent_id && e.folder_id >= 0) {
                e.parent_id = e.folder_id
            }
            if (e[target]) {
                if (!e.document_type) {
                    e.type = type
                    if (isArray(e[target])) {
                        e[target].map((el) => {
                            el.type = type
                        })
                    }
                } else {
                    e.type = from
                    e[target].map((el) => {
                        el.type = 'files'
                        el.parent_id = el.document_id
                    })
                }
            }
            if (!e[target] && !e[from]) {
                if (e.document_id) {
                    e.type = 'files'
                } else {
                    e.type = type
                }
            }
            if (e[from] && from !== target) {
                e.type = type
                if (isArray(e[from])) {
                    e[from].map((c) => {
                        c.type = from
                    })
                    e[target] = [...e[target], ...e[from]]
                }
            }
            if (e[target] && e[target].length) {
                e[target].map((el) => {
                    if (!el.parent_id && el.folder_id >= 0) {
                        el.parent_id = el.folder_id
                    }
                })
                gr(e[target], from, target, type)
            }
        })
    }

    gr(array, from, target, type)

    return array
}

const hasDeep = (array, key, value, deepBy) => {
    let flag = true

    function hd(array, key, deepBy) {
        if (isArray(array)) {
            array.map((e) => {
                if (e[key] === value) {
                    flag = false
                } else if (e[deepBy] && e[deepBy].length) {
                    hd(e[deepBy], key, deepBy)
                }
            })
        }
    }

    hd(array, key, deepBy)

    return flag
}

const flattenDeep = (array, deep) => {
    let result = []

    function fd(array, deep) {
        array.map((e) => {
            result.push(e)
            if (e[deep] && e[deep].length) {
                fd(e[deep], deep)
            }
        })
    }

    fd(array, deep)

    return result
}

export {
    findDeep,
    setDeep,
    getDeep,
    checkDeep,
    groupDeep,
    hasDeep,
    flattenDeep,
}