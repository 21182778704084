<template>
  <div>
    <ul class="item-sub" v-if="isShown">
      <template v-for="(e, i) in listData">
        <li :key="'folder-' + e.id + '-' + i"
            class="item d-flex cursor-pointer align-items-center"
            :class="{'is-active': isActive && checkContains(e.id)}"
            v-if="!e.parent_id || checkContains(e.parent_id)">
          <hr class="line-connect-child-item"/>
          <input type="radio"
                 v-if="userInfo && (userInfo.role_id !== 4 || (userInfo.office_id === e.office || userInfo.office_id === e.office_id) || (userInfo.role_id === 4 && e.id === defaultSelected))"
                 class="cursor-pointer m__left--20"
                 name="chosen-folder"
                 :checked="value && e.id === value.id"
                 :value="{id: e.id, name: e.name}"
                 v-model="chosenFolder">
          <img class="icon-folder-tree m__right--10 m__left--10"
               @click="toggleChild(e.id)"
               src="@/assets/svgs/ic_File_1.svg">
          <div @click="toggleChild(e.id)" class="folder-box has-text-nowrap folder-name d-flex align-items-center">
            <span class="color__gray_tree m__right--18 fs-14 fw__bold">
              {{ e.name }}
            </span>
            <a class="icon-caret-down"
               v-if="e.children && e.children.length">
              <img width="11px" src="@/assets/svgs/icon_drop.svg"/>
            </a>
          </div>
        </li>
        <nested-tree v-if="e.children && e.children.length"
                     :list-data="e.children"
                     :active-id="activeIds"
                     :default-selected="defaultSelected"
                     v-model="chosenFolder"
                     @change="getChosenFolder($event)"
                     :key="'recursive-' + e.id + '-' + i">
        </nested-tree>
      </template>
    </ul>
  </div>
</template>

<script>
import findIndex from "lodash/findIndex";

export default {
  name: "nested-tree",
  props: {
    listData: {
      type: [Array, Object],
      default: () => {
      }
    },
    activeId: {
      required: false,
      type: Array,
      default: () => []
    },
    value: {
      type: [Object, Number, String],
      default: () => {
      }
    },
    defaultSelected: {
      type: [Number, String],
      default: null
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      selectedData: {},
      isActive: false,
      activeIds: [],
      chosenFolder: this.value,
      isShown: true,
    }
  },
  watch: {
    activeId(val) {
      this.isShown = false
      this.activeIds = [...val]
      this.$nextTick(() => {
        this.isShown = true
      })
    },
    value(val) {
      if (val) {
        this.chosenFolder = val
      }
    },
    chosenFolder(val) {
      this.$emit('change', val)
    }
  },
  methods: {
    getChosenFolder(e) {
      this.chosenFolder = e
    },
    checkContains(id) {
      if(this.activeIds && this.activeIds.length) {
        return findIndex(this.activeIds, (e) => e === id) >= 0
      }
      return false
    },
    toggleChild(id) {
      if (id) {
        this.isActive = !this.isActive
        if (findIndex(this.activeIds, (e) => e === id) >= 0) {
          this.activeIds.splice(findIndex(this.activeIds, (e) => e === id), 1)
        } else {
          this.activeIds = [...this.activeIds, ...[id]]
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.item-sub {
  margin: 0 0 0 8rem;
  border-left: 1px solid lightgrey;
}

.icon-folder-tree {
  width: 20px;
}
.folder-box {
  background-color: $gray_dark;
  padding: 2px 15px;
  border-radius: 12px;

  &:hover {
    span {
      color: $blue_main !important;
    }
  }
}
  .item {
  padding: 1.5rem 1rem 0 1rem;
  background-color: #fff;
  position: relative;
  width: fit-content;

  .line-connect-child-item {
    width: calc(1rem + 20px);
    margin: 0;
    background-color: lightgrey;
    position: absolute;
    left: 0;
    top: 75%;
    height: 1px;
  }

  .icon-caret-down {
    img {
      color: #808080;
      transform: rotateZ(0deg);
      transition: all 0.3s ease-in-out;
    }
  }

  &.is-active {
    .icon-caret-down {
      img {
        transform: rotateZ(180deg);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
.modal-choose-folder {
  .modal-card-choose-folder-body {
    li.item {
      width:max-content !important;
    }
  }
}
</style>
