import isObject from 'lodash/isObject'

export default class BaseModel {
  constructor(props = null) {
    this.props = props
  }

  syncProps(props) {
    if (props && isObject(props))
      Object.keys(props).map(name => {
        if (name in this) {
          if (isObject(this[name])) {
            try {
              this[name].syncProps(props[name])
            } catch (e) {
              this[name] = props[name]
            }
          } else
            this[name] = props[name]
        } else {
          Object.assign(this, {[name]: props[name]})
        }

      })

    return this;
  }

  // todo design code
  syncAllProps(props) {
    console.log(props)
  }

  addUserColumn() {
    Object.assign(this, {
      created_user_id: '',
      updated_user_id: ''
    })

    return this
  }

  addTimesColumn(deleteFlag = true) {
    const obj = {
      created_at: '',
      updated_at: '',
    }

    if (deleteFlag)
      Object.assign(obj, {deleted_at: ''})

    Object.assign(this, obj)

    return this
  }

  rawValue() {
    //todo fix me
    const data = {}
    const noMap = ['__errors__', '__model__', '_autoKey']
    Object.keys(this).forEach(keyName => {
      if (noMap.indexOf(keyName) === -1) {
        if (isObject(this[keyName])) {
          try {
            data[keyName] = this[keyName].rawValue()
          } catch (e) {
            data[keyName] = this[keyName]
          }
        } else if (Array.isArray(this[keyName])) {
          data[keyName] = this[keyName]
          data[keyName] = data[keyName].map((item) => {
            try {
              return item.rawValue()
            } catch (e) {
              return item
            }
          })
        } else {
          data[keyName] = this[keyName]
        }
      }
    })

    return data;
  }
}
