<template>
  <div class="add-document-content">
    <div class="add-document-title">
      <p class="title-secondary fs-20 color__blue_main fw__bold">{{ title || $t('documents.page.create') }}</p>
    </div>
    <div class="page-main-content mt-0" @keyup.enter="createDocument()">
      <div class="columns p--0 m--0">
        <div class="column is-12 p--0">
          <div class="columns align-items-center">
            <div class="column is-4 p__top--0">
              <div class="m__bottom--6">
                <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('documents.page.add') }}</span>
              </div>
              <div>
                <button class="button br--5 w--100 background__blue_background button-folder" @click="chooseFolder()">
                  <div class="tree-folder-name fs-14 color__blue_button_text">{{ selectedFolder.name || $t('documents.button.choose_folder') }}</div>
                  <img class="icon-folder-tree"
                       src="@/assets/svgs/ic_File_1.svg" alt="">
                </button>
              </div>
            </div>
          </div>
          <hr/>

          <div class="columns d-flex">
            <div class="column p__right--25">
              <div class="align-items-center m__bottom--16">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('documents.input_field.name.label') }}<i class="required-note" :data-tooltip="$t('tooltip.required')">*</i></span>
                </div>
                <div class="">
                  <label>
                    <input class="input fs-14 fw__bold color__blue_button"
                           v-model.trim="document.name"
                           :class="{'is-error': ((!vuelidate.document.name.required || !vuelidate.document.name.maxLength) && vuelidate.$dirty) || (error_server.name && error_server.name.length)}"
                           type="text"
                           :placeholder="$t('documents.input_field.name.placeholder')">
                  </label>
                  <span class="error pt-1" v-if="error_server.name && error_server.name.length">
                {{ error_server.name[0] }}
              </span>
                  <template v-else-if="vuelidate.$dirty">
                <span class="error pt-1" v-if="!vuelidate.document.name.required">
                  {{ $t('documents.messages.validation.name_required') }}
                </span>
                    <span class="error pt-1" v-if="!vuelidate.document.name.maxLength">
                  {{ $t('documents.messages.validation.name_maxLength') }}
                </span>
                  </template>
                </div>
              </div>
              <div class="align-items-center m__bottom--16">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('documents.input_field.documents.division') }}</span>
                </div>
                <div>
                  <label v-if="userInfo && userInfo.role_id === 4">
                    <input class="input" :value="document.division_name" type="text" placeholder="" disabled>
                  </label>
                  <template v-else>
                    <dropdown-search :list="divisionsFiltered"
                                     :is-invalid="!vuelidate.document.division_id.required && vuelidate.$dirty"
                                     display="name"
                                     id-prefix="divisions"
                                     :placeholder="$t('documents.input_field.documents.placeholder_division')"
                                     :selected="document.division_id"
                                     @change="getDocumentInformation('division_id', $event, 'dropdown-division')">
                    </dropdown-search>
                    <span class="error pt-1" v-if="!vuelidate.document.division_id.required && vuelidate.$dirty">
                  {{ $t('users_management.messages.validation.division_required') }}
                </span>
                  </template>
                </div>
              </div>
              <div class="align-items-center m__bottom--16">
                <div class="m__bottom--6">
                     <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('search_documents.input.doc_type.label') }}<i class="required-note" :data-tooltip="$t('tooltip.required')">*</i></span>
                </div>
                <div>
                  <div class="field">
                    <div class="control">
                      <label v-if="isUpdate">
                        <input class="input fs-14 color__blue_button fw__bold" :value="document.document_type_name" type="text" placeholder="" disabled>
                      </label>
                      <div class="dropdown w--100"
                           v-else
                           id="dropdown-document-type"
                           v-click-out="()=>closeDropdown('dropdown-document-type')">
                        <div class="dropdown-trigger w--100">
                          <button class="button w--100 is-justify-content-flex-start br--5 fs-14 color__blue_button button-muti"
                                  aria-haspopup="true"
                                  :disabled="isUpdate"
                                  :class="{'is-error': (!vuelidate.document.document_type.required && vuelidate.$dirty) || (error_server.document_type && error_server.document_type.length)}"
                                  @click="toggleDropdown('dropdown-document-type')">
                          <span
                            :class="{'color-error': (!vuelidate.document.document_type.required && vuelidate.$dirty) || (error_server.document_type && error_server.document_type.length)}">
                            <span class="color__blue_main" v-if="document.document_type_name">{{ document.document_type_name }}</span>
                            <span class="color__placeholder fs-14"
                                v-else>{{ $t('search_documents.input.doc_type.placeholder') }}</span>
                          </span>
                          <span class="icon is-small" style="opacity: 0.7; margin-right: -.3rem"
                                  :class="{'color-error': (!vuelidate.document.document_type.required && vuelidate.$dirty) || (error_server.document_type && error_server.document_type.length) }">
                            <img width="15px" src="@/assets/svgs/ic_drop_2.svg"/>
                          </span>
                          </button>
                          <span class="error pt-1" v-if="!vuelidate.document.document_type.required && vuelidate.$dirty">
                        {{ $t('documents.messages.validation.document_type_required') }}
                      </span>
                          <span class="error pt-1"
                                v-else-if="error_server.document_type && error_server.document_type.length">
                        {{ error_server.document_type[0] }}
                      </span>
                        </div>
                        <div class="dropdown-menu w--100" role="menu">
                          <div class="dropdown-content">
                            <a @click="getDocumentType(e)"
                               v-for="(e, i) in documentTypes"
                               :key="'doc-type-' + i"
                               :class="{'is-active': e.code === document.document_type}"
                               class="dropdown-item fs-14">
                              {{ e.name }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="document.document_type">
                <div class="document-type-b2b m__bottom--16" v-if="selectedDocumentType.case === 'partner_name'">
                  <div class="">
                    <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('documents.contract_partner.label') }}<i class="required-note" :data-tooltip="$t('tooltip.required')">*</i></span>
                  </div>
                  <div class="">
                    <label>
                      <input class="input"
                             :disabled="isUpdate"
                             :class="{'is-error': isSubmitted && !document.partner_name}"
                             type="text"
                             :placeholder="$t('documents.contract_partner.placeholder')"
                             v-model.trim="document[selectedDocumentType.case]">
                    </label>
                    <span class="error pt-1" v-if="isSubmitted && !document.partner_name">
                  {{ $t('documents.messages.validation.partner_name_required') }}
                </span>
                    <span class="error pt-1" v-if="document.partner_name && document.partner_name.length > 50">
                  {{ $t('documents.messages.validation.partner_name_maxlength') }}
                </span>
                  </div>
                </div>
              </template>
              <div class="m__bottom--16">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('documents.tag.label') }}
                   </span>
                </div>
                <div class="">
                  <div class="control is-fullwidth list-tags">
                    <multiselect :list-data="tags"
                                 :placeholder="$t('documents.tag.placeholder')"
                                 :create-new="true"
                                 :integer-value="false"
                                 :remove-item="false"
                                 string-compare="id"
                                 :max-length="50"
                                 msg-max-length="documents.messages.validation.tags_maxlength"
                                 msg-duplicate="documents.messages.validation.tags_duplicate"
                                 :deletable="!isUpdate || (userInfo && userInfo.role_id !== 4) || (isUpdate && documentDetail.owner && userInfo && documentDetail.owner.id === userInfo.id)"
                                 :selected="selectedTags"
                                 @submit-parent-form="createDocument()"
                                 @create-item="createTag($event)"
                                 @remove-item="deleteTag($event)"
                                 @change="getTagsSelected($event)">
                    </multiselect>
                  </div>
                </div>
              </div>
              <div>
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('documents.file') }}<i :data-tooltip="$t('tooltip.required')" class="required-note">*</i></span>
                </div>
                <div>
                  <div class="is-flex">
                    <div id="file-js-example" class="file has-name">
                      <label class="file-label">
                    <span class="file-cta br--5 d-flex align-items-center is-justify-content-space-between"
                          :class="{'is-error color-error': (!vuelidate.document.files_info.required && vuelidate.$dirty) || (document.files_info.length && !checkFilesValidate(document.files_info, 'is_delete', false)) || (error_server.files_info && error_server.files_info.length) || totalFilesSize > maxFilesSize || totalFiles > maxFiles}">
                      <span class="file-label fs-14 color__blue_button" style="line-height: 14px">
                          {{ $t('documents.select.file') }}
                      </span>
                      <span class="d-flex">
                        <img width="25px" src="@/assets/svgs/ic_upload.svg">
                      </span>
                    </span>
                        <input type="file"
                               accept=".jpg,.jpeg,.png,.doc,.docx, .pdf,.xlsx,.xls,.xlsm,.ppt,.pps,.pptx,.ppsx,.txt,.zip,.rar"
                               ref="fileUpload"
                               class="file-input input-upload"
                               id="file-upload"
                               multiple
                               @change="getFileUpload($event)">
                      </label>
                    </div>
                  </div>
                  <span class="error pt-1 fs-14"
                        v-if="(!vuelidate.document.files_info.required && vuelidate.$dirty) || (document.files_info.length && !checkFilesValidate(document.files_info, 'is_delete', false))">
                {{ $t('documents.messages.validation.files_required') }}
              </span>
                  <span class="error pt-1 fs-14" v-else-if="error_server['files_info.0.file'] && error_server['files_info.0.file'].length">
                {{ error_server['files_info.0.file']}}
              </span>
                  <span class="error pt-1 fs-14" v-else-if="totalFilesSize > maxFilesSize">
                {{ $t('documents.messages.validation.files_max_size') }}
              </span>
                  <span class="error pt-1 fs-14" v-else-if="totalFiles > maxFiles">
                {{ $t('documents.messages.validation.max_files') }}
              </span>
                  <div class="is-flex mt-3" v-if="document.files_info && document.files_info.length">
                    <ul class="list-file">
                      <template v-for="(e, i) in document.files_info">
                        <li :key="'file-' + i" class="item-file-uploaded m__bottom--5" v-if="!e.is_delete">
                          <div class="tags has-addons tag-file-uploaded cursor-pointer"
                               :class="{'is-error': e.overSize || e.wrongFormat}">
                            <span class="tag is-justify-content-start" style="width: 70%">
                              <span v-if="isCreate">
                                 <img src="@/assets/svgs/Files/ic_doc.svg" class="icon-file m__right--10"
                                      v-if="((e.file ? (e.file.type.includes('.document') ? true : e.file.type.includes('msword')) : false))">
                              </span>
                              <span v-if="!isCreate">
                                 <img src="@/assets/svgs/Files/ic_doc.svg" class="icon-file m__right--10"
                                      v-if="(e.file && (e.file.type.includes('.document') || e.file.type.includes('msword')))">
                                 <img src="@/assets/svgs/Files/ic_doc.svg" class="icon-file m__right--10"
                                     v-else-if="(!e.file && e.file_format && e.file_format * 1 === 1)">
                              </span>
                              <span v-if="isCreate">
                                <img src="@/assets/svgs/Files/ic_PDF.svg" class="icon-file m__right--10"
                                     v-if="(e.file ? e.file.type.includes('pdf') : false)">
                              </span>
                              <span v-if="!isCreate">
                                <img src="@/assets/svgs/Files/ic_PDF.svg" class="icon-file m__right--10"
                                     v-if="(e.file && e.file.type.includes('pdf'))">
                                <img src="@/assets/svgs/Files/ic_PDF.svg" class="icon-file m__right--10"
                                     v-else-if="(!e.file  && e.file_format && e.file_format * 1 === 2)">
                              </span>
                              <span v-if="isCreate">
                                <img src="@/assets/svgs/Files/ic_image.svg" class="icon-file m__right--10"
                                     v-if="(e.file)? e.file.type.includes('image') : false">
                              </span>
                              <span v-if="!isCreate">
                                <img src="@/assets/svgs/Files/ic_image.svg" class="icon-file m__right--10"
                                     v-if="(e.file && e.file.type.includes('image'))">
                                <img src="@/assets/svgs/Files/ic_image.svg" class="icon-file m__right--10"
                                     v-else-if="(!e.file && e.file_format && e.file_format * 1 === 3)">
                              </span>
                              <span v-if="isCreate">
                                <img src="@/assets/svgs/Files/ic_excel.svg" class="icon-file m__right--10"
                                     v-if="(e.file)? e.file.type.includes('.sheet') : false">
                              </span>
                              <span v-if="!isCreate">
                                <img src="@/assets/svgs/Files/ic_excel.svg" class="icon-file m__right--10"
                                     v-if="(e.file && e.file.type.includes('.sheet'))">
                                <img src="@/assets/svgs/Files/ic_excel.svg" class="icon-file m__right--10"
                                     v-else-if="(!e.file && e.file_format && e.file_format * 1 === 4)">
                              </span>
                               <span v-if="isCreate">
                                <img src="@/assets/svgs/Files/ic_PPTX.svg" class="icon-file m__right--10"
                                     v-if="(e.file)? (e.file.type.includes('.presentation') ? true : e.file.type.includes('.ms-powerpoint')) : false">
                              </span>
                              <span v-if="!isCreate">
                                <img src="@/assets/svgs/Files/ic_PPTX.svg" class="icon-file m__right--10"
                                     v-if="(e.file && (e.file.type.includes('.presentation') || e.file.type.includes('.ms-powerpoint')))">
                                <img src="@/assets/svgs/Files/ic_PPTX.svg" class="icon-file m__right--10"
                                     v-else-if="(!e.file && e.file_format && e.file_format * 1 === 5)">
                              </span>
                              <span v-if="isCreate">
                                <img src="@/assets/svgs/Files/ic_txt.svg" class="icon-file m__right--10"
                                     v-if="(e.file)? e.file.type.includes('text') : false">
                              </span>
                              <span v-if="!isCreate">
                                <img src="@/assets/svgs/Files/ic_txt.svg" class="icon-file m__right--10"
                                     v-if="(e.file && e.file.type.includes('text'))">
                                <img src="@/assets/svgs/Files/ic_txt.svg" class="icon-file m__right--10"
                                     v-else-if="(!e.file && e.file_format && e.file_format * 1 === 6)">
                              </span>
                              <span v-if="isCreate">
                                <img src="@/assets/svgs/Files/ic_Zip_1.svg" class="icon-file m__right--10"
                                     v-if="((e.file)? (e.file.type.includes('zip')? true : e.file.name.includes('.rar')) : false)">
                              </span>
                              <span v-if="!isCreate">
                                <img src="@/assets/svgs/Files/ic_Zip_1.svg" class="icon-file m__right--10"
                                     v-if="((e.file)? (e.file.type.includes('zip')? true : e.file.name.includes('.rar')) : false)">
                                <img src="@/assets/svgs/Files/ic_Zip_1.svg" class="icon-file m__right--10"
                                     v-else-if="(!e.file && e.file_format && e.file_format * 1 === 7)">
                              </span>
                              {{ e.displayName || e.file.name }}
                            </span>
                            <label class="tag cursor-pointer btn-up-version"
                                   :data-tooltip="$t('documents.tooltip.up_version')" v-if="isUpdate && e.id">
                              <img width="20px" src="@/assets/svgs/ic_upload.svg">
                              <input type="file"
                                     accept=".jpg,.jpeg,.png,.doc,.docx, .pdf,.xlsx,.xls,.xlsm,.ppt,.pps,.pptx,.ppsx,.txt,.zip,.rar"
                                     :ref="'upVersion-' + i"
                                     class="file-input input-upload"
                                     @change="getFileUpload($event, e.id, i)">
                            </label>
                            <a class="tag btn-remove-file"
                               @click="deleteFileUploaded(i)"
                               :data-tooltip="$t('documents.tooltip.remove_file')"
                               v-if="!isUpdate || (userInfo && userInfo.role_id !== 4) || (isUpdate && documentDetail.owner && userInfo && documentDetail.owner.id === userInfo.id && (!e.id || documentDetail.document_type && documentDetail.document_type.id !== 5)) || !e.id">
                              <i class="fas fa-times"></i>
                            </a>
                          </div>
                          <span v-if="e.overSize"
                                class="color-error fs-14">{{ $t('documents.messages.validation.over_size') }}</span>
                          <span v-else-if="e.wrongFormat"
                                class="color-error fs-14">{{ $t('documents.messages.validation.wrong_format') }}</span>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="column p__left--25">
              <div class="align-items-center m__bottom--16">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('documents.input_field.documents.branch') }}</span>
                </div>
                <div>
                  <label v-if="userInfo && userInfo.role_id === 4">
                    <input class="input" :value="document.branch_name" type="text" placeholder="" disabled>
                  </label>
                  <template v-else>
                    <dropdown-search :list="branches"
                                     :is-invalid="!vuelidate.document.branch_id.required && vuelidate.$dirty"
                                     display="name"
                                     id-prefix="branches"
                                     :placeholder="$t('users_management.labels.input_field.branch.placeholder')"
                                     :selected="document.branch_id"
                                     @change="getDocumentInformation('branch_id', $event, 'dropdown-branch')">
                    </dropdown-search>
                    <span class="error pt-1" v-if="!vuelidate.document.branch_id.required && vuelidate.$dirty">
                  {{ $t('users_management.messages.validation.branch_required') }}
                </span>
                  </template>
                </div>
              </div>
              <div class="align-items-center m__bottom--16">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('documents.input_field.documents.office') }}</span>
                </div>
                <div class="">
                  <label v-if="userInfo && userInfo.role_id === 4">
                    <input class="input" :value="document.office_name" type="text" placeholder="" disabled>
                  </label>
                  <template v-else>
                    <dropdown-search :list="officesFiltered"
                                     :is-invalid="!vuelidate.document.office_id.required && vuelidate.$dirty"
                                     display="name"
                                     id-prefix="offices"
                                     :placeholder="$t('users_management.labels.input_field.office.placeholder')"
                                     :selected="document.office_id"
                                     @change="getDocumentInformation('office_id', $event, 'dropdown-office')">
                    </dropdown-search>
                    <span class="error pt-1" v-if="!vuelidate.document.office_id.required && vuelidate.$dirty">
                  {{ $t('users_management.messages.validation.office_required') }}
                </span>
                  </template>
                </div>
              </div>
              <div class="m__bottom--16" v-if="attributes && attributes.length">
                <div class="m__bottom--6">
                  <span class="input-title fs-14 color__blue_button fw__bold">{{ $t('documents.doc_attributes.label') }}</span>
                </div>
                <div class="">
                  <div class="field">
                    <div class="control">
                      <div class="dropdown w--100"
                           id="dropdown-attributes"
                           v-click-out="()=>closeDropdown('dropdown-attributes')">
                        <div class="dropdown-trigger w--100">
                          <button class="button w--100 is-justify-content-flex-start button-attr br--5"
                                  aria-haspopup="true"
                                  @click="toggleDropdown('dropdown-attributes')">
                            <span class="fs-14" style="color: #B8CAD1">{{ $t('documents.doc_attributes.placeholder') }}</span>
                            <span class="icon is-small" style=" margin-right: -.3rem">
                                <img width="15px" src="@/assets/svgs/ic_drop_2.svg"/>
                             </span>
                          </button>
                        </div>
                        <div class="dropdown-menu w--100" role="menu">
                          <div class="dropdown-content">
                            <a @click="getSelectedAttributes(e)"
                               v-for="(e, i) in attributes"
                               :key="'doc-type-' + i"
                               :class="{'is-active': isSelected(e.id)}"
                               class="dropdown-item">
                              {{ e.name }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m__bottom--16" v-if="selectedAttributes && selectedAttributes.length">
                <div class=""></div>
                <div class="">
                  <div class="field">
                    <template v-for="(e, i) in selectedAttributes">
                      <span class="input-title fs-14 color__blue_button fw__bold" :key="'custom-label-' + i">{{ e.name || ('属性 ' + (i + 1)) }}</span>
                      <custom-input :type="e.type"
                                    :index="i"
                                    @invalid="customInputInvalid($event)"
                                    :max-range="maxRange"
                                    :max-date="maxDate"
                                    :attribute-id="e.id"
                                    :attribute-type="e.type"
                                    v-model="customInput[i]"
                                    :options="e.value"
                                    :key="'custom-input-' + i">
                      </custom-input>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div class="columns p__bottom--14">
            <div class="column is-full">
              <div class="field is-grouped is-grouped-centered">
                <button class="button p-0 btn-save background__blue_button_add mr-4" @click="createDocument()">
                  {{ buttonText || $t('buttons.register') }}
                </button>
                <button class="button btn-back" @click="backToList()">{{ $t('buttons.cancel_add') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal modal-choose-folder" id="modal-choose-folder">
        <div class="modal-background"></div>
        <div class="modal-card" v-click-out="() => closeModal('modal-choose-folder')">
          <template v-if="isChooseFolder && !isAddServiceUser">
            <div v-click-out="cancelFolder">
              <div class="modal-card-head background__gray_dark">
                <p class="modal-card-title input-title color__blue_main text-center fw__bold fs-20">{{ $t('documents.select.folder') }}</p>
                <button class="delete" aria-label="close" @click="closeModal('modal-choose-folder')"></button>
              </div>
              <section class="modal-card-body modal-card-choose-folder-body"
                       @keyup.enter.stop="selectFolder()">
                <div class="columns modal-field-content d-flex p__0">
                  <div class="column is-full">
                    <div class="item d-flex cursor-pointer align-items-center">
                      <label class="d-flex">
                        <input type="radio"
                               v-if="(userInfo && userInfo.role_id !== 4) || (documentDetail && !documentDetail.folder)"
                               class="cursor-pointer m__left--20"
                               name="chosen-folder"
                               :value="{id: null, name: 'Root'}"
                               v-model="chosenFolder">
                      </label>
                      <img class="icon-folder-tree m__right--10 m__left--10"
                           src="@/assets/svgs/ic_File_1.svg" alt="">
                      <div class="root">
                        <span class="fs-14 fw__bold">Root</span>
                      </div>
                    </div>
                    <nested-tree :list-data="listFolders"
                                 :default-selected="(documentDetail && documentDetail.folder) ? documentDetail.folder.id : null"
                                 :active-id="activeFolders" v-model="chosenFolder"></nested-tree>
                  </div>
                </div>
              </section>
              <div class="modal-card-foot is-justify-content-center background__white">
                <button class="button is-success close-modal p-0 btn-save background__blue_main"
                        @click="selectFolder()">{{ $t('documents.button.select_folder') }}
                </button>
                <button class="button btn-back close-modal"
                        @click="cancelFolder(); closeModal('modal-choose-folder')">{{ $t('documents.button.cancel') }}
                </button>
              </div>
            </div>
          </template>
          <template v-if="!isChooseFolder && isAddServiceUser">
            <div v-click-out="cancelServiceUser">
              <div class="modal-card-head background__gray_dark">
                <p class="modal-card-title color__blue_main input-title text-center fw__bold">{{ $t('user_service.page.create') }}</p>
                <button class="delete" aria-label="close" @click="closeModal('modal-choose-folder')"></button>
              </div>
              <section class="modal-card-body" @keyup.enter.stop="createServiceUser()">
                <div class="column is-full p-0">
                  <div class="m__bottom--10">
                    <div class="d-flex align-items-center m__bottom--6">
                      <span class="input-title fs-14 color__blue_main fw__bold">
                        {{ $t('documents.input_field.service_code.label') }}
                        <i :data-tooltip="$t('tooltip.required')" class="required-note">*</i>
                      </span>
                    </div>
                    <div>
                      <label>
                        <input type="text"
                               :class="{'is-error': isSubmittedServiceUser && (!userService.code || !XSSCheck(userService.code) || userService.code.length > 20 || serviceUserDuplicated(userService.code) || error_server.code && error_server.code.length)}"
                               :placeholder="$t('documents.input_field.service_code.placeholder')"
                               v-model.trim="userService.code"
                               class="input">
                      </label>
                      <template v-if="isSubmittedServiceUser">
                      <span class="error pt-1" v-if="!userService.code">
                        {{ $t('user_service.validation.code.required') }}
                      </span>
                        <span class="error pt-1" v-else-if="!XSSCheck(userService.code)">
                        {{ $t('user_service.validation.code.xss_check') }}
                      </span>
                        <span class="error pt-1" v-else-if="userService.code.length > 20">
                        {{ $t('user_service.validation.code.maxlength') }}
                      </span>
                        <span class="error pt-1" v-else-if="serviceUserDuplicated(userService.code)">
                        {{ $t('user_service.validation.code.duplicated') }}
                      </span>
                        <span class="error pt-1" v-else-if="error_server.code && error_server.code.length">
                        {{ error_server.code[0] }}
                      </span>
                      </template>
                    </div>
                  </div>
                  <div>
                    <div class="d-flex align-items-center">
                    <span class="input-title fs-14 color__blue_main fw__bold">
                      {{ $t('documents.input_field.service_name.label') }}
                      <i :data-tooltip="$t('tooltip.required')" class="required-note">*</i>
                    </span>
                    </div>
                    <div>
                      <label>
                        <input type="text"
                               :class="{'is-error': isSubmittedServiceUser && (!userService.name || userService.name.length > 50)}"
                               :placeholder="$t('documents.input_field.service_name.placeholder')"
                               v-model.trim="userService.name"
                               class="input">
                      </label>
                      <template v-if="isSubmittedServiceUser">
                      <span class="error pt-1" v-if="!userService.name">
                        {{ $t('user_service.validation.name.required') }}
                      </span>
                        <span class="error pt-1" v-else-if="userService.name.length > 50">
                        {{ $t('user_service.validation.name.maxlength') }}
                      </span>
                      </template>
                    </div>
                  </div>
                </div>
              </section>
              <div class="modal-card-foot is-justify-content-center background__white">
                <button class="button is-success close-modal btn-save background__blue_main"
                        @click="createServiceUser()">{{ buttonText || $t('buttons.save') }}
                </button>
                <button class="button btn-back close-modal"
                        @click="cancelServiceUser(); closeModal('modal-choose-folder')">{{ $t('buttons.cancel') }}
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentModel from "@/models/DocumentModel"
import DocumentService from "@/services/DocumentService"
import User from '@/mixins/user'
import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions"
import isArray from 'lodash/isArray'
import toArray from 'lodash/toArray'
import compact from 'lodash/compact'
import truncate from 'lodash/truncate'
import findIndex from 'lodash/findIndex'
import uniqBy from 'lodash/uniqBy'
import CustomInput from "@/views/documents/components/custom-input"
import FolderService from "@/services/FolderService"
import NestedTree from "@/components/common/nested-tree"
import {documentTypeMapper} from "@/helpers/constant"
import {maxLength, required} from "vuelidate/lib/validators"
import i18n from "@/lang/i18n"
// import InputSearch from "@/components/common/input-search"
import moment from 'moment'
import {checkDeep, getDeep} from "@/helpers/recursive";
import {XSSCheck} from '@/helpers/utils';
import {UserUsageService} from "@/services";
import {ACTION_SET_SPECIAL_LOADING} from "@/stores/common/actions";

export default {
  name: "DocumentCreate",
  components: {NestedTree, CustomInput},
  mixins: [User],
  props: {
    documentDetail: {
      type: [Object, Array],
      default: () => {
      },
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      document: new DocumentModel(),
      documentTypes: [],
      attributes: [],
      selectedAttributes: [],
      fileUpload: [],
      tags: [],
      selectedTags: [],
      customInput: [],
      listFolders: [],
      serviceUsers: [],
      chosenFolder: {},
      selectedFolder: {},
      tempSelectedFolder: {},
      selectedDocumentType: {},
      isSubmitted: false,
      isSubmittedServiceUser: false,
      typing: '',
      keyword: '',
      fileUploads: null,
      activeFolders: [],
      divisionsFiltered: [],
      officesFiltered: [],
      error_server: {},
      maxRange: '',
      maxDate: '',
      isChooseFolder: false,
      isAddServiceUser: false,
      userService: {
        code: '',
        name: '',
      },
      systemTags: [],
      customDateValid: true,
      maxFilesSize: 314572800,
      maxFileSize: 52428800,
      maxFiles: 30,
      totalFilesSize: 0,
      totalFiles: 0,
      submitAvailable: true,
      oldServiceUSer: {},
      documentObjects: [],
      selectedDocumentObjects: {},
      paginate: {
        currentPage: null,
        lastPage: null,
        perPage: null,
        total: null
      },
      isLazyLoadServiceUsers: false,
    }
  },
  validations: {
    document: {
      name: {
        required,
        maxLength: maxLength(50)
      },
      document_type: {
        required
      },
      files_info: {
        required,
      },
      branch_id: {
        required,
      },
      division_id: {
        required,
      },
      office_id: {
        required,
      },

      // tags: {
      //   required
      // },
    }
  },
  computed: {
    folderId() {
      return this.$route.query.parent_id
    },
    folderName() {
      return this.$route.query.parent_name
    },
    isCreate() {
      return this.$route.name === 'CreateDocument'
    }
  },
  watch: {
    userInfo(val) {
      if (val && !this.isUpdate) {
        this.document.office_id = val.office.id
        this.document.office_name = val.office.name
        this.document.division_id = val.office.division.id
        this.document.division_name = val.office.division.name
        this.document.branch_id = val.office.division.branch.id
        this.document.branch_name = val.office.division.branch.name
      }
    },
    documentDetail(val) {
      if (val) {
        this.document.id = val.id
        this.document.name = val.name
        this.document.folder_id = val.folder && val.folder.id
        this.tempSelectedFolder = val.folder && val.folder.id
        // this.document.branch_id = val.office && val.office.division.branch.id
        // this.document.division_id = val.office && val.office.division.id
        // this.document.office_id = val.office && val.office.id
        this.document.document_type = val.document_type && val.document_type.code
        this.documentTypes.map((e) => {
          if (e.code === this.document.document_type) {
            this.documentObjects = e.objects ? [...e.objects] : []
          }
        })
        this.document.document_type_name = val.document_type.name
        this.$set(this.document, 'service_user', {...val.service_user})
        this.oldServiceUSer = {...this.document.service_user}
        // this.$nextTick(() => {
        //   if (!this.serviceUsers || !this.serviceUsers.length) {
        //     this.getServiceUsers()
        //   } else {
        //     this.$set(this, 'serviceUsers', this.serviceUsers)
        //   }
        // })
        this.userService = {...val.service_user}
        this.document.partner_name = val.partner_name
        this.$nextTick(() => {
          if (val.office) {
            this.divisionsFiltered = []
            this.divisions.map((e) => {
              if (e.branch_id === val.office.division.branch.id) {
                this.divisionsFiltered = [...this.divisionsFiltered, ...[e]]
              }
            })
            this.officesFiltered = []
            this.offices.map((e) => {
              if (e.division_id === val.office.division.id) {
                this.officesFiltered = [...this.officesFiltered, ...[e]]
              }
            })
            this.$set(this.document, 'branch_id', val.office.division.branch.id)
            this.$set(this.document, 'division_id', val.office.division.id)
            this.$set(this.document, 'office_id', val.office.id)
            this.$set(this.document, 'branch_name', val.office.division.branch.name)
            this.$set(this.document, 'division_name', val.office.division.name)
            this.$set(this.document, 'office_name', val.office.name)
            this.$set(this.document, 'document_object', val.document_object && val.document_object.code)
            this.selectedDocumentObjects = val.document_object
          }
        })
        val.attributes.map((e, i) => {
          if (e.pivot.value) {
            this.customInput[i] = {
              id: e.id,
              type: e.type,
              value: e.pivot.value
            }
            this.getSelectedAttributes(e)
          }
          if (e.type === 8) {
            this.customInput[i] = {
              id: e.id,
              type: e.type,
              value: {
                start: e.pivot.start_date ? new Date(e.pivot.start_date) : '',
                end: e.pivot.end_date ? new Date(e.pivot.end_date) : ''
              }
            }
            this.getSelectedAttributes(e)
          }
          if (!e.pivot.value && !e.pivot.start_date && !e.pivot.end_date && e.type !== 8) {
            this.customInput[i] = {
              id: e.id,
              type: e.type,
              value: ''
            }
            this.getSelectedAttributes(e)
          }
        })
        this.document.files_info = val.files.data
        this.document.files_info.map((e) => {
          e.displayName = this.getDisplayFileName(e.original_name)
        })
        this.document.tags = []
        val.tags && val.tags.map((e) => {
          this.document.tags = [...this.document.tags, ...[e.name]]
          if (!this.selectedTags.includes(e.id)) {
            this.selectedTags = [...this.selectedTags, ...[e.id]]
          }
        })
        this.chosenFolder = val.folder ? {id: val.folder.id, name: val.folder.name} : {id: null, name: 'Root'}
        this.selectedFolder = this.chosenFolder
        this.getFolders()
        this.closeDropdown('dropdown-attributes')
        this.getDocumentTypes()
      } else {
        this.chosenFolder = {id: null, name: 'Root'}
        this.selectedFolder = this.chosenFolder
        this.getFolders()
      }
    },
    // branches() {
    //   if (this.userInfo) {
    //     this.document.office_id = this.userInfo.office.id
    //     this.document.office_name = this.userInfo.office.name
    //     this.document.division_id = this.userInfo.office.division.id
    //     this.document.division_name = this.userInfo.office.division.name
    //     this.document.branch_id = this.userInfo.office.division.branch.id
    //     this.document.branch_name = this.userInfo.office.division.branch.name
    //   }
    // },
    // 'document.branch_id': function (val) {
    //   this.divisionsFiltered = []
    //   this.divisions.map((e) => {
    //     if (e.branch_id === val) {
    //       this.divisionsFiltered = [...this.divisionsFiltered, ...[e]]
    //     }
    //   })
    // },
    divisions(val) {
      this.divisionsFiltered = []
      val.map((e) => {
        if (e.branch_id === this.document.branch_id) {
          this.divisionsFiltered = [...this.divisionsFiltered, ...[e]]
        }
      })
      // if (this.userInfo) {
      //   this.document.office_id = this.userInfo.office.id
      //   this.document.office_name = this.userInfo.office.name
      //   this.document.division_id = this.userInfo.office.division.id
      //   this.document.division_name = this.userInfo.office.division.name
      //   this.document.branch_id = this.userInfo.office.division.branch.id
      //   this.document.branch_name = this.userInfo.office.division.branch.name
      // }
    },
    // 'document.division_id': function (val) {
    //   this.officesFiltered = []
    //   this.offices.map((e) => {
    //     if (e.division_id === val) {
    //       this.officesFiltered = [...this.officesFiltered, ...[e]]
    //     }
    //   })
    // },
    offices(val) {
      this.officesFiltered = []
      val.map((e) => {
        if (e.division_id === this.document.division_id) {
          this.officesFiltered = [...this.officesFiltered, ...[e]]
        }
      })
      // if (this.userInfo) {
      //   this.document.office_id = this.userInfo.office.id
      //   this.document.office_name = this.userInfo.office.name
      //   this.document.division_id = this.userInfo.office.division.id
      //   this.document.division_name = this.userInfo.office.division.name
      //   this.document.branch_id = this.userInfo.office.division.branch.id
      //   this.document.branch_name = this.userInfo.office.division.branch.name
      // }
    },
    attributes(val) {
      this.document.attribute_values = []
      val.map((e) => {
        let index = findIndex(this.customInput, el => el && el.id === e.id)
        if (index >= 0) {
          let value = {...this.customInput[index]}
          if (value && value.type === 6) {
            this.$set(this, 'maxRange', value.value || '')
            value.value = value.value ? moment(value.value).format('YYYY-MM-DD') : ''
          }
          if (value && value.type === 8) {
            this.$set(this, 'maxDate', value.value.end || '')
            value.value = {
              start: value.value.start ? moment(value.value.start).format('YYYY-MM-DD') : '',
              end: value.value.end ? moment(value.value.end).format('YYYY-MM-DD') : '',
            }
          }
          let data = {
            selected: true,
            attribute_id: value.id,
            values: isArray(value) ? [...value.value] : [...[value.value]]
          }
          this.document.attribute_values = [...this.document.attribute_values, ...[data]]
        }
      })
    },
    customInput(val) {
      this.document.attribute_values = []
      this.attributes.map((e) => {
        let index = findIndex(val, el => el && el.id === e.id)
        if (index >= 0) {
          let value = {...val[index]}
          if (value && value.type === 6) {
            this.$set(this, 'maxRange', value.value || '')
            value.value = value.value ? moment(value.value).format('YYYY-MM-DD') : ''
          }
          if (value && value.type === 8) {
            this.$set(this, 'maxDate', value.value.end || '')
            value.value = {
              start: value.value.start ? moment(value.value.start).format('YYYY-MM-DD') : '',
              end: value.value.end ? moment(value.value.end).format('YYYY-MM-DD') : '',
            }
          }
          let data = {
            selected: true,
            attribute_id: value.id,
            values: isArray(value) ? [...value.value] : [...[value.value]]
          }
          this.document.attribute_values = [...this.document.attribute_values, ...[data]]
        }
      })
    },
    // userInfo(val) {
    //   if (val && !this.isUpdate) {
    //     this.document.office_id = val.office.id
    //     this.document.office_name = val.office.name
    //     this.document.division_id = val.office.division.id
    //     this.document.division_name = val.office.division.name
    //     this.document.branch_id = val.office.division.branch.id
    //     this.document.branch_name = val.office.division.branch.name
    //   }
    // },
    // chosenFolder(val) {
    //   this.tempSelectedFolder = val
    // }
  },
  methods: {
    XSSCheck(val) {
      return XSSCheck(val)
    },
    getDisplayFileName(fileName) {
      let displayName = ''
      let ext = fileName.split('.').pop()
      let name = ''
      fileName.split('.').map((e, i) => {
        if (i < fileName.split('.').length - 1) {
          name += e
        }
      })
      if (fileName && fileName.length > 20) {
        displayName = truncate(name, {
          'length': 20,
          'omission': '...'
        }) + '.' + ext
      } else {
        displayName = fileName
      }

      return displayName
    },
    async getFolders() {
      await FolderService.list((this.isUpdate && this.userInfo && this.userInfo.role_id === 4) ? this.document.id : null)
          .then((res) => {
            if (res && res.data) {
              this.listFolders = res.data.folders
              if (this.selectedFolder && this.selectedFolder.id) {
                let data = getDeep(this.listFolders, this.selectedFolder.id, 'id', 'children', 'parent_id')
                this.activeFolders = data.map((e) => {
                  return e.id
                }).reverse()
                this.listFolders = checkDeep(this.listFolders, 'id', [this.selectedFolder.id], 'checked', 'children')
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error(err.data.message)
          })
    },
    chooseFolder() {
      this.tempSelectedFolder = {}
      this.isChooseFolder = true
      this.isAddServiceUser = false
      // this.chosenFolder = this.selectedFolder
      this.toggleModal('modal-choose-folder')
      setTimeout(() => {
        this.modalOpened = true
      })
    },
    selectFolder() {
      this.selectedFolder = this.chosenFolder
      this.document.folder_id = this.selectedFolder.id
      this.closeModal('modal-choose-folder')
    },
    cancelFolder() {
      this.chosenFolder = this.selectedFolder
      // this.closeModal('modal-choose-folder')
    },
    cancelServiceUser() {
      // this.closeModal('modal-choose-folder')
      this.$set(this.document, 'service_user', {...this.oldServiceUSer})
      this.isSubmittedServiceUser = false
      this.userService = {}
    },
    serviceUserDuplicated(code) {
      let index = findIndex(this.serviceUsers, e => e.code === code)
      return index >= 0 && this.serviceUsers[index].id
    },
    createServiceUser() {
      this.isSubmittedServiceUser = true
      if (this.userService
          && this.userService.code
          && this.XSSCheck(this.userService.code)
          && this.userService.code.length <= 20
          && !this.serviceUserDuplicated(this.userService.code)
          && this.userService.name
          && this.userService.name.length <= 50) {
        UserUsageService.create(this.userService)
            .then((res) => {
              if (res && res.data) {
                this.serviceUsers = [...this.serviceUsers, ...[res.data]]
                this.serviceUsers.map((e) => {
                  e.specialDisplay = e.code + ' - ' + e.name
                })
                this.$set(this.document, 'service_user', {...res.data})
                this.oldServiceUSer = {...res.data}
              }
              this.closeModal('modal-choose-folder')
            })
            .catch((err) => {
              this.submitAvailable = true
              this.error_server = err.data.errors
            })

      }
    },
    async getDocumentTypes() {
      await DocumentService.documentTypes()
          .then((res) => {
            if (res && res.data) {
              this.documentTypes = res.data.document_types
              this.documentTypes.map((e) => {
                if (e.code === this.document.document_type) {
                  this.documentObjects = e.objects ? [...e.objects] : []
                }
                e.case =
                    findIndex(documentTypeMapper, (el) => el.code === e.code) >= 0
                        ? documentTypeMapper[findIndex(documentTypeMapper, (el) => el.code === e.code)].case
                        : ''
              })
              const typeIndex = findIndex(this.documentTypes, (e) => e.code === this.document.document_type)
              this.selectedDocumentType = this.documentTypes[typeIndex]
              // if (typeIndex >= 0) {
              //   this.attributes = [...this.documentTypes[typeIndex].attributes]
              //   this.document.attribute_values = []
              //   this.attributes.map((e) => {
              //     let index = findIndex(this.customInput, el => el && el.id === e.id)
              //     let value = {...this.customInput[index]}
              //     if (value && value.type === 6) {
              //       value.value = value.value ? moment(value.value).format('YYYY-MM-DD') : ''
              //     }
              //     if (value && value.type === 8) {
              //       value.value = {
              //         start: value.value.start ? moment(value.value.start).format('YYYY-MM-DD') : '',
              //         end: value.value.end ? moment(value.value.end).format('YYYY-MM-DD') : '',
              //       }
              //     }
              //     let data = {
              //       attribute_id: e.id,
              //       values: value ? (isArray(value.value) ? [...value.value] : [...[value.value]]) : null
              //     }
              //     this.document.attribute_values = [...this.document.attribute_values, ...[data]]
              //   })
              // }
            }
          })
          .catch((err) => {
            console.log(err)
            this.$toast.error(i18n.t('documents.messages.validation.no_doc_type'))
          })
    },
    async getAttributes() {
      await DocumentService.getAttributes()
          .then((res) => {
            if (res && res.data) {
              this.attributes = res.data.attributes
            }
          })
          .catch((err) => {
            console.log(err)
          })
    },
    async getTags() {
      await DocumentService.getTags()
          .then((res) => {
            if (res && res.data) {
              this.tags = res.data.tags
              this.systemTags = [...res.data.tags]
            }
          })
          .catch((err) => {
            console.log(err)
            this.$toast.error(i18n.t('documents.messages.validation.no_tags'))
          })
    },
    async createTag(name) {
      let tag = {
        id: name,
        name
      }
      this.tags = [...this.tags, ...[tag]]
      this.document.tags = [...this.document.tags, ...[name]]
      this.selectedTags = [...this.selectedTags, ...[name]]
    },
    async deleteTag(e) {
      let indexSystem = findIndex(this.systemTags, el => el.id === e.id)
      let indexTags = findIndex(this.tags, el => el.id === e.id)
      let index = findIndex(this.document.tags, el => el === e.name)
      if (indexSystem >= 0) {
        this.$popup(
            'Delete',
            'Delete this tag?',
            {
              okText: this.$t('folders.popup.delete_folder.ok'),
              cancelText: this.$t('folders.popup.delete_folder.cancel'),
              iconClass: "info-circle",
              type: "warning",
            },
            {
              onOk: async (d) => {
                await DocumentService.deleteTag(e.id)
                    .then(() => {
                      if (indexTags >= 0) {
                        this.tags.splice(indexTags, 1)
                      }
                      if (index >= 0) {
                        this.document.tags.splice(index, 1)
                        this.selectedTags.splice(index, 1)
                      }
                      this.$toast.success(i18n.t('documents.messages.validation.remove_tag'))
                    })
                    .catch((err) => {
                      console.log(err)
                      // this.$toast.error(i18n.t('documents.messages.validation.no_delete_tag'))
                    })
                d.closeDialog()
              },
              onCancel: (d) => {
                d.closeDialog()
              },
              onClickOut: (d) => {
                d.closeDialog()
              }
            }
        )
      } else {
        if (indexTags >= 0) {
          this.tags.splice(indexTags, 1)
        }
        if (index >= 0) {
          this.document.tags.splice(index, 1)
          this.selectedTags.splice(index, 1)
        }
      }
    },
    getTagsSelected(e) {
      this.document.tags = []
      this.selectedTags = []
      e.map((tag) => {
        this.document.tags = [...this.document.tags, ...[tag.name]]
        this.selectedTags = [...this.selectedTags, ...[tag.id]]
      })
    },
    getDocumentType(type) {
      // this.attributes = []
      this.documentObjects = []
      this.customInput = []
      this.document.service_user = {}
      this.document.partner_name = ''
      this.customInput = []
      this.$nextTick(() => {
        this.selectedDocumentType = type
        this.document.document_type = type.code
        this.document.document_type_name = type.name
        this.closeDropdown('dropdown-document-type')
        // this.attributes = [...type.attributes]
        this.documentObjects = type.objects ? [...type.objects] : []
      })
    },
    lazyLoadServiceUsers(event) {
      this.getServiceUsers(event.keyword, event.search, event.page, event.per_page, true)
    },
    getServiceUsers(keyword = '', search = false, page = 1, per_page = 10, lazy = false) {
      if (search) {
        this.$store.dispatch(ACTION_SET_SPECIAL_LOADING, 1)
      }
      if (lazy) {
        this.isLazyLoadServiceUsers = true
      }
      this.$nextTick(() => {
        DocumentService.getServiceUsers({keyword, page, per_page})
            .then((res) => {
              this.$store.dispatch(ACTION_SET_SPECIAL_LOADING, 0)
              if (res) {
                if (lazy) {
                  this.serviceUsers = this.oldServiceUSer && this.oldServiceUSer.code ? uniqBy([...[this.oldServiceUSer], ...this.serviceUsers, ...res.data.service_users], 'id') : uniqBy([...this.serviceUsers, ...res.data.service_users], 'id')
                } else {
                  this.serviceUsers = this.oldServiceUSer && this.oldServiceUSer.code ? uniqBy([...[this.oldServiceUSer], ...res.data.service_users], 'id') : res.data.service_users
                }
                this.paginate = {
                  currentPage: res.data.current_page,
                  lastPage: res.data.last_page,
                  perPage: res.data.per_page * 1,
                  total: res.data.total,
                }
                this.serviceUsers.map((e) => {
                  e.specialDisplay = e.code + ' - ' + e.name
                })
                this.documentDetail && this.$set(this.document, 'service_user', {...this.documentDetail.service_user})
                this.oldServiceUSer = {...this.document.service_user}
                this.isLazyLoadServiceUsers = false
              }
            })
            .catch((err) => {
              this.$store.dispatch(ACTION_SET_SPECIAL_LOADING, 0)
              console.log(err)
              this.$toast.error(i18n.t('documents.messages.validation.no_get_service_user'))
              this.isLazyLoadServiceUsers = false
            })
      })
    },
    getServiceUser(e) {
      if (e.code) {
        this.serviceUsers = uniqBy([...this.serviceUsers, ...[e]], 'id')
        let index = findIndex(this.serviceUsers, el => el.code === e.code)
        if (index >= 0) {
          this.$set(this.document, 'service_user', {
            id: this.serviceUsers[index].id,
            code: this.serviceUsers[index].code,
            name: this.serviceUsers[index].name
          })
          this.oldServiceUSer = {...this.document.service_user}
        }
      } else {
        this.document.service_user = {...this.oldServiceUSer}
      }
      // this.toggleDropdown('dropdown-service-users')
    },
    addUserService() {
      this.isChooseFolder = false
      this.isAddServiceUser = true
      this.oldServiceUSer = Object.assign(this.document.service_user)
      this.toggleModal('modal-choose-folder')
      setTimeout(() => {
        this.modalOpened = true
      })
    },
    getTyping(e) {
      this.typing = e
    },
    getKeyword(e) {
      this.keyword = e
    },
    // getCode(e) {
    //   this.document.service_user.id = e.id
    //   this.document.service_user.code = e.code
    // },
    // getName(e) {
    //   this.document.service_user.id = e.id
    //   this.document.service_user.name = e.name
    // },
    checkValidate() {
      let flag = false
      if (this.document.document_type) {
        switch (this.document.document_type) {
          case '0003': {
            flag = !!this.document.partner_name
                && this.document.partner_name.length <= 50
            break
          }
          default: {
            flag = true
            break
          }
        }
      } else {
        flag = false
      }

      return flag
    },
    getSelectedDocumentObject(e) {
      this.selectedDocumentObjects = e
      this.document.document_object = e.code
      this.closeDropdown('dropdown-document-objects')
    },
    getSelectedAttributes(e) {
      let data = [...this.selectedAttributes]
      this.selectedAttributes = []
      if (this.isSelected(e.id, data)) {
        e.selected = false
        let index = findIndex(data, (el) => el.id === e.id)
        data.splice(index, 1)
        this.customInput = [...compact(this.customInput)]
        let valIndex = findIndex(this.customInput, (el) => el.id === e.id)
        if (this.customInput[valIndex] && this.customInput[valIndex].type === 8) {
          this.maxDate = ''
        }
        this.customInput.splice(valIndex, 1)
        let resIndex = findIndex(this.document.attribute_values, (el) => el.attribute_id === e.id)
        this.document.attribute_values.splice(resIndex, 1)
      } else {
        e.selected = true
        data = [...data, ...[e]]
      }
      this.selectedAttributes = [...data]
      // this.document.attribute_values.map(e => e.selected = true)
      this.toggleDropdown('dropdown-attributes')
    },
    isSelected(id, list = this.selectedAttributes) {
      let flag = false
      list.map((e) => {
        if (e.id === id) {
          flag = true
        }
      })
      return flag
    },
    getDocumentInformation(key, value, el = '') {
      if (this.document[key] !== value.id) {
        this.document[key] = value.id
        this.closeDropdown(el)
        switch (key) {
          case 'branch_id': {
            this.$set(this.document, 'division_id', null)
            this.$set(this.document, 'office_id', null)
            this.divisionsFiltered = []
            this.officesFiltered = []
            this.divisions.map((e) => {
              if (e.branch_id === value.id) {
                this.divisionsFiltered = [...this.divisionsFiltered, ...[e]]
              }
            })
            break
          }
          case 'division_id': {
            this.$set(this.document, 'office_id', null)
            this.officesFiltered = []
            this.offices.map((e) => {
              if (e.division_id === value.id) {
                this.officesFiltered = [...this.officesFiltered, ...[e]]
              }
            })
            break
          }
          default: {
            break
          }
        }
      }
    },
    customInputInvalid(e) {
      this.customDateValid = !e
    },
    createDocument() {
      this.isSubmitted = true
      this.vuelidate.$touch()
      this.error_server = {}
      const flag = this.customDateValid && this.totalFilesSize <= this.maxFilesSize && this.totalFiles <= this.maxFiles && this.checkValidate() && this.checkFilesValidate(this.document.files_info, 'overSize') && this.checkFilesValidate(this.document.files_info, 'wrongFormat') && this.checkFilesValidate(this.document.files_info, 'is_delete', false)
      if (!this.vuelidate.$invalid && flag && this.submitAvailable) {
        let data = new FormData()
        this.document.files_info.map((e, i) => {
          if (e.id !== null) {
            data.append(`files_info[${i}][id]`, e.id)
            e.is_delete && data.append(`files_info[${i}][is_delete]`, e.is_delete)
          }
          if (e.file) {
            data.append(`files_info[${i}][file]`, e.file)
          } else {
            data.append(`files_info[${i}][file]`, e.original_name)
          }
        })
        this.document.folder_id && data.append("folder_id", this.document.folder_id)
        let attr_value = compact(this.document.attribute_values.map(e => {
          let index = findIndex(this.selectedAttributes, {id: e.attribute_id})
          if (index >= 0) {
            return e
          }
        }))
        data.append("name", this.document.name)
        data.append("branch_id", this.document.branch_id)
        data.append("division_id", this.document.division_id)
        data.append("office_id", this.document.office_id)
        data.append("partner_name", this.document.partner_name)
        data.append("document_type", this.document.document_type)
        data.append("attribute_values", JSON.stringify(attr_value))
        data.append("tags", this.document.tags)
        this.submitAvailable = false
        if (this.document.id) {
          data.append('version', this.documentDetail.version)
          data.append('_method', 'PUT')
          DocumentService.update(this.document.id, data).then(() => {
            this.submitAvailable = true
            this.$toast.success(this.$t('documents.messages.update_success'))
            this.$router.go(-1)
          }).catch((err) => {
            this.submitAvailable = true
            this.error_server = err.data.errors
            this.$emit('reload-data')
            // this.$toast.error(this.$t('documents.messages.update_failed'))
          })
        } else {
          DocumentService.create(data).then((res) => {
            this.submitAvailable = true
            this.$toast.success(this.$t('documents.messages.create_success'))
            if (res && res.data) {
              this.$router.push({
                name: res.data.document_type.type === 1 ? 'BtoB' : 'BtoC',
                params: {id: res.data.id},
                query: {sid: res.data.service_user && res.data.service_user.id}
              }, () => {
              })
            }
          }).catch((err) => {
            this.submitAvailable = true
            this.getServiceUsers()
            this.error_server = err.data.errors
          })
        }
      }
    },
    getFileType(filename) {
      let parts = filename.split('.')
      return parts[parts.length - 1]
    },
    isCorrectFileFormat(ext) {
      let paths = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx','xlsx','xls','xlsm','ppt','pps','pptx','ppsx','txt','rar','zip']
      return paths.includes(ext.toLowerCase())
    },
    checkFilesValidate(files, type, condition = true) {
      let flag = condition
      files.map((e) => {
        if (condition ? e[type] : !e[type]) {
          flag = !condition
        }
      })
      return flag
    },
    getFileUpload(e, id = null, i = null) {
      e.preventDefault()
      if (!id) {
        if (this.$refs.fileUpload && this.$refs.fileUpload.files && this.$refs.fileUpload.files.length) {
          toArray(this.$refs.fileUpload.files).map((e) => {
            let data = {
              id: id,
              file: e,
              displayName: this.getDisplayFileName(e.name),
              overSize: e.size > this.maxFileSize,
              wrongFormat: !this.isCorrectFileFormat(this.getFileType(e.name)),
            }
            this.fileUpload = [...this.fileUpload, ...[data]]
          })
          this.document.files_info = [...this.document.files_info, ...this.fileUpload]
          this.$refs.fileUpload.value = null
          this.fileUpload = []
        }
      } else {
        if (this.$refs['upVersion-' + i]
            && this.$refs['upVersion-' + i][0]
            && this.$refs['upVersion-' + i][0].files
            && this.$refs['upVersion-' + i][0].files[0]) {
          let index = findIndex(this.document.files_info, e => e.id === id)
          if (index >= 0) {
            this.$set(this.document.files_info[index], 'file', this.$refs['upVersion-' + i][0].files[0])
            this.$set(this.document.files_info[index], 'original_name', '')
            this.$set(this.document.files_info[index], 'overSize', this.$refs['upVersion-' + i][0].files[0].size > this.maxFileSize)
            this.$set(this.document.files_info[index], 'displayName', this.getDisplayFileName(this.$refs['upVersion-' + i][0].files[0].name))
            this.$set(this.document.files_info[index], 'wrongFormat', !this.isCorrectFileFormat(this.getFileType(this.document.files_info[index].file.name)))
          }
          this.$refs['upVersion-' + i].value = null
        }
      }
      this.totalFilesSize = 0
      this.totalFiles = 0
      this.document.files_info.map((e) => {
        if (!e.is_delete) {
          this.totalFilesSize += e.file ? e.file.size : 0
        }
        if (!e.is_delete && e.file) {
          this.totalFiles += 1
        }
      })
    },
    deleteFileUploaded(i) {
      if (this.document.files_info[i].id) {
        this.$set(this.document.files_info[i], 'is_delete', true)
      } else {
        this.document.files_info.splice(i, 1)
      }
      this.totalFilesSize = 0
      this.totalFiles = 0
      this.document.files_info.map((e) => {
        if (!e.is_delete) {
          this.totalFilesSize += e.file ? e.file.size : 0
        }
        if (!e.is_delete && e.file) {
          this.totalFiles += 1
        }
      })
    },
    backToList() {
      this.$router.go(-1)
    }
  },
  created() {
    this.getData('offices')
    this.getData('divisions')
    this.getData('branches')
    this.chosenFolder = {id: this.folderId * 1 || null, name: this.folderName || 'Root'}
    this.selectedFolder = this.chosenFolder
    if (!this.documentDetail) {
      this.getFolders()
      this.getServiceUsers()
    }
    this.getDocumentTypes()
    this.getTags()
    this.getAttributes()
    this.document.folder_id = this.folderId * 1 || null
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'documents')
  },
  mounted() {
    setTimeout(() => {
      if (this.userInfo) {
        this.document.office_id = this.userInfo.office.id
        this.document.office_name = this.userInfo.office.name
        this.document.division_id = this.userInfo.office.division.id
        this.document.division_name = this.userInfo.office.division.name
        this.document.branch_id = this.userInfo.office.division.branch.id
        this.document.branch_name = this.userInfo.office.division.branch.name
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.add-document-content {
  background-color: $white;
  border-radius: 10px;
  .add-document-title {
    padding: 10px 60px;
    background-color: $bg_add_document;
    border-radius: 10px 10px 0 0 ;
  }
  .page-main-content {
    padding: 26px 60px;
    border-radius: 0 0 10px 10px;
    .columns {
      margin: 0;
      .column {
        padding-right: 0px;
        padding-left: 0px;
      }

    }
  }
}
.modal-card-foot {
  padding-bottom: 60px !important;
  padding-top: 40px !important;
}
input {
  &.input {
    height: 40px;
    padding-left: 20px;
    font-size: 12px;
    color: $blue_button;
    font-weight: bold;
    border-radius: 5px !important;
  }
  &:hover {
    border-color: $blue_main !important;
    box-shadow: none !important;
  }
}
button {
  &.button-muti {
    height: 40px;
    padding-left: 20px;
  }
  &.button-attr {
    &:focus{
      box-shadow: none !important;
    }
  }
}
.list-file {
  width: 100%;
}
.root {
  background: #F1F1F1;
  padding: 2px 15px;
  border-radius: 12px;
  color: #8E9DA3 !important;
  span:hover {
    color: $blue_main;
  }
}
.input-upload {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  padding: 0;
}
.file-cta {
  border: none;
  background-color: $blue_background;
  width: 220px;
}
.plus-top {
  width: 80%;
}
.dropdown-trigger {
  button {
    &:hover {
      border-color: $blue_main !important;
      box-shadow: none !important;
    }
  }
}
button {
  &.button-folder {
    border: none !important;
  }
  &.btn-save {
    border-radius: 18px !important;
    height: 35px !important;
    width: 140px !important;
    border:  none !important;
    font-size: 16px !important;
  }
  &.btn-back {
    border-radius: 18px !important;
    height: 35px !important;
    width: 140px !important;
    border:  none !important;
    font-size: 16px !important;
    background-color: $white_pink !important;
    color: $btn_back !important;
  }
}
.modal-card-body {
  padding-left: 100px;
  padding-right: 100px;
}
.tag-file-uploaded {
  margin-bottom: 0 !important;

  &:hover, &:active, &:focus {
    outline: none;
  }

  &.is-error {
    box-shadow: unset !important;

    .tag {
      margin-bottom: 0 !important;

      &:hover, &:active, &:focus {
        outline: none;
      }

      color: $danger !important;
      border-top: 1px solid $danger;
      border-bottom: 1px solid $danger;

      &:first-child {
        border-left: 1px solid $danger;
        border-right: none;
      }

      &:last-child {
        border-left: none;
        border-left: none;
        border-right: 1px solid $danger;
      }
    }
  }

  .tag {
    background-color: #cac9c9 !important;
    margin-bottom: 0px;
    &.btn-up-version {
      height: 30px;
      background-color: #F1F1F1 !important;
      &:hover {
        color: $success !important;

        svg {
          transform: scale(1.2);
          transition: all 0.3s ease-in-out;
        }
      }
    }

    &.btn-remove-file {
      &:hover {
        color: $danger !important;

        svg {
          transform: scale(1.2);
          transition: all 0.2s ease-in-out;
        }
      }
    }

    &:first-child {
      border-radius: 5px 0 0 5px !important;
      height: 30px;
      background-color: #F1F1F1 !important;
      color: $gray_text_1 !important;
    }

    &:last-child {
      border-radius: 0 5px 5px 0 !important;
      height: 30px;
      background-color: #F1F1F1 !important;
      color: $gray_text_1 !important;
    }
  }
}

.modal-choose-folder {
  .modal-card-choose-folder-body {
    height: calc(80vh - 200px);
    overflow: hidden;
    padding: 0;

    .modal-field-content {
      height: 100%;

      .column {
        overflow-y: scroll;
      }
    ;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.modal {
  .modal-card {
    border-radius: 25px;

    .modal-card-body {

      .modal-field-content {
        padding: 0 20px;

        .toggle-password {
          position: absolute;
          top: 10px;
          right: 12px;
          color: gray;
          z-index: 10;

          &:hover {
            cursor: pointer;
          }
        }

        .dropdown-content {
          max-height: 200px;
          overflow-y: scroll;
        }
      }
    }
  }
}
.item {
  padding: 1.5rem 1rem 0 1rem;
  background-color: #fff;
  position: relative;
  width: fit-content;
}
.icon {
  opacity: 1 !important;
}
.icon-folder-tree {
  width: 25px;
}

.tree-folder-name {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: initial;
}

@media screen and (min-width: 769px) {
  .modal-content, .modal-card {
    width: 840px;
  }
}

.blob {
  width: 34px;
  height: 34px;
  top: 1px;
  left: 1px;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .plus {
    padding-top: 10px !important;
    margin-top: 0 !important;
    text-align: right;

    .blob {
      margin-right: 0 !important;
    }
  }
  .page-main-content {
    padding: 26px 60px;
    .columns {
      margin: 0;
      .column {
        padding-right: 0px;
        padding-left: 0px;
      }

    }
  }
}

@media screen and (max-width: 495px) {
  .page-main-content {
    .column {
      padding: 15px;
    }

    .m__bottom--20 {
      margin-bottom: 0 !important;
    }

    .m__top--10 {
      margin-top: 0 !important;
    }

    .service_user {
      padding: 15px;
    }

    .plus-top {
      padding-bottom: 0 !important;
    }

    .plus {
      padding-top: 10px !important;
      margin-top: -5px !important;
      text-align: right;

      .blob {
        margin-right: 0 !important;
      }
    }
  }
}

.dropdown-objects {
  max-height: 300px;
  overflow-y: auto;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .modal-choose-folder {
    .modal-card-choose-folder-body {
      height: calc(80vh - 200px);
      overflow: hidden;
      padding: 0;

      .modal-field-content {
        height: 100%;

        .column {
          overflow-y: scroll !important;
        }
      ;
        padding: 0 !important;
      }
    }
  }
  .add-document-content {
    .add-document-title {
      padding: 10px 20px;
    }
    .page-main-content {
      padding: 26px 20px;
    }
  }
}
</style>
